import React from 'react';
import { Auth, I18n } from 'aws-amplify';
import { AmplifyAuthenticator, AmplifySignIn } from '@aws-amplify/ui-react';
import {
  AuthState,
  onAuthUIStateChange,
  Translations,
} from '@aws-amplify/ui-components';
import { AUTH_CONFIG, TOKEN_DISABLED } from './utils/configs';
import App from './App';

I18n.putVocabulariesForLanguage('en-AU', {
  [Translations.BACK_TO_SIGN_IN]: 'Back to log in',
  [Translations.SIGN_IN_ACTION]: 'Log in',
  [Translations.FORGOT_PASSWORD_TEXT]: ' ',
  [Translations.RESET_PASSWORD_TEXT]: 'Forgot password',
  [Translations.SIGN_IN_HEADER_TEXT]: 'Log in to your account',
  [Translations.SIGN_OUT]: 'Log out',
  [Translations.VERIFY_CONTACT_HEADER_TEXT]: 'Choose your recovery option',
});
I18n.setLanguage('en-AU');

Auth.configure(AUTH_CONFIG);

const AuthStateApp = () => {
  const [authState, setAuthState] = React.useState();
  const [user, setUser] = React.useState();

  React.useEffect(() => {
    return onAuthUIStateChange((nextAuthState, authData) => {
      setAuthState(nextAuthState);
      setUser(authData);
    });
  }, []);

  return authState === AuthState.SignedIn && user ? (
    <App />
  ) : (
    <div className="login">
      <div className="login__container">
        <div className="login__logo" />
        <AmplifyAuthenticator>
          <AmplifySignIn slot="sign-in" hideSignUp={true} />
          <div slot="primary-footer-content" style={{ width: '100%' }} />
        </AmplifyAuthenticator>
      </div>
    </div>
  );
};

const AppWithAuth = TOKEN_DISABLED ? App : AuthStateApp;

export default AppWithAuth;
