import {
  useState,
  useEffect,
  useCallback,
  useContext,
  createContext,
} from 'react';
import HttpClient from '../utils/HttpClient';

const PayrollInfoContext = createContext();

export const ProvidePayrollInfo = ({ children }) => {
  const [payrollInfo, setPayrollInfo] = useState(null);

  const getPayrollInfo = useCallback(async () => {
    try {
      const res = await HttpClient.get('/channel/info');
      const { data } = res;
      setPayrollInfo(data);
    } catch (error) {
      console.log(error);
    }
  }, []);

  useEffect(() => {
    getPayrollInfo();
  }, [getPayrollInfo]);

  return payrollInfo != null ? (
    <PayrollInfoContext.Provider value={payrollInfo}>
      {children}
    </PayrollInfoContext.Provider>
  ) : null;
};

export const usePayrollInfo = () => useContext(PayrollInfoContext);
