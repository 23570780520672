import { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { usePayrollInfo } from './hooks/usePayrollInfo';

const Theme = () => {
  const { channelId } = usePayrollInfo() || null;

  useEffect(() => {
    if (channelId != null) {
      // Remove default bond theme
      const element = window.document.body;
      element.classList.remove('bond');
    }
  }, [channelId]);

  if (channelId == null) {
    return null;
  }

  return (
    <Helmet>
      <link
        rel="stylesheet"
        type="text/css"
        href={`${process.env.REACT_APP_BASE_URL}/themes/${channelId}/theme.css`}
      />
      <link
        rel="icon"
        type="image/png"
        href={`${process.env.REACT_APP_BASE_URL}/themes/${channelId}-favicon-32x32.png`}
      />
    </Helmet>
  );
};

export default Theme;
