import {
  useState,
  useEffect,
  useCallback,
  useContext,
  createContext,
} from 'react';
import HttpClient from '../utils/HttpClient';

const ConfigContext = createContext();

export const ProvideConfig = ({ children }) => {
  const [config, setConfig] = useState(null);

  const getConfig = useCallback(async () => {
    try {
      const res = await HttpClient.get('/config');
      const { data } = res;
      setConfig(data);
    } catch (error) {
      console.log(error);
    }
  }, []);

  useEffect(() => {
    getConfig();
  }, [getConfig]);

  return config != null ? (
    <ConfigContext.Provider value={config}>{children}</ConfigContext.Provider>
  ) : null;
};

export const useConfig = () => useContext(ConfigContext);
