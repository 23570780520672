import { lazy, Suspense } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import ScrollToTop from './components/ScrollToTop/ScrollToTop';
import Loading from './components/Loading/Loading';
import { ProvideConfig } from './hooks/useConfig';
import { ProvidePayrollInfo } from './hooks/usePayrollInfo';
import useUserRoles from './hooks/useUserRoles';
import { ToastContainer, Slide } from 'react-toastify';
import { USER_ROLES } from './utils/configs';
import Theme from './Theme';

const AdminRoutes = lazy(() => import('./AdminRoutes'));
const UserRoutes = lazy(() => import('./UserRoutes'));

const App = () => {
  const userRoles = useUserRoles();
  const isAdmin =
    userRoles.includes(USER_ROLES.ADMIN) ||
    userRoles.includes(USER_ROLES.WORKFLOW_ADMIN);

  return userRoles.length > 0 ? (
    <ProvideConfig>
      <ProvidePayrollInfo>
        <Theme />
        <div className="app">
          <Router>
            <ScrollToTop />
            <Suspense fallback={<Loading />}>
              {isAdmin ? <AdminRoutes /> : <UserRoutes />}
            </Suspense>
          </Router>
          <ToastContainer
            position="top-center"
            transition={Slide}
            autoClose={false}
            hideProgressBar
            pauseOnFocusLoss
            newestOnTop={false}
            closeOnClick
          />
        </div>
      </ProvidePayrollInfo>
    </ProvideConfig>
  ) : null;
};

export default App;
