import { useEffect, useState } from 'react';
import HttpClient from '../utils/HttpClient';
import { TOKEN_DISABLED } from '../utils/configs';
import { Auth } from 'aws-amplify';

const useUserRoles = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(TOKEN_DISABLED);
  const [userRoles, setUserRoles] = useState([]);

  useEffect(() => {
    (async () => {
      try {
        const data = await Auth.currentSession();
        console.log('Auth.currentSession()', data);
        setIsAuthenticated(true);
      } catch (error) {
        console.log(error);
      }
    })();
  }, []);

  useEffect(() => {
    const getUserRoles = async () => {
      try {
        const res = await HttpClient.get('/user/details');
        console.log(res.data.roles);
        setUserRoles(res.data.roles);
      } catch (error) {
        console.log(error);
      }
    };

    getUserRoles();
  }, [isAuthenticated]);

  return userRoles;
};

export default useUserRoles;
