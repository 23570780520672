import axios from 'axios';
import { Auth } from 'aws-amplify';
import { API_HOST, TOKEN_DISABLED } from './configs';
import signOut from './signOut';

const HttpClient = axios.create({
  baseURL: API_HOST,
  headers: process.env.REACT_APP_AUTH_DISABLED_HEADER
    ? JSON.parse(process.env.REACT_APP_AUTH_DISABLED_HEADER)
    : undefined,
});

if (!TOKEN_DISABLED) {
  HttpClient.interceptors.request.use(
    async (config) => {
      try {
        const authData = await Auth.currentSession();
        const token = authData.idToken.jwtToken;
        config.headers.Authorization = token;
        return config;
      } catch {
        window.alert('Your access is expired. Please sign back in.');
        signOut();
        throw new axios.Cancel('Operation canceled by the user.');
      }
    },
    (error) => {
      return Promise.reject(error);
    }
  );
}

export default HttpClient;
